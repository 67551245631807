.body{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.63); 
  z-index: 999;
  inset: 0 0 -32px 0;
}
.bounce2 {
  animation: bounce2 2s ease infinite;
}
@keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}
.chat-icon1{
  width: 125px;
  height: 95px;
}
.chat-icon{
  width: 150px;
  height: 120px;
}

.chatbot {
  display: flex;
  flex-direction: column;
  height: 600px;
  width: 350px;
  border-radius: 7px;
  box-shadow:0px 0px 5px 5px rgb(87 87 87 / 50%);
  background-color: #c5392e;
  position:fixed; 
  bottom:115px; 
  right:30px;
  font-family: 'Roboto';
}

.chatbot1 {
  display: flex;
  flex-direction: column;
  height: 600px;
  width: 350px;
  border-radius: 7px;
  box-shadow:0px 0px 5px 5px rgb(87 87 87 / 50%);
  position:fixed; 
  bottom: 115px; 
  right:30px;
}


.chat-logo-box{
  width: 260px;
  height: 50px;
}
.chat-logo{
  width: inherit;
  height: inherit;
}

.chatbot-messages{
  margin-top: 40px;
  margin-left: 24px ;
  margin-right: 24px;
}

 .chatbot-header {
  display: block;
  align-items: left;
  justify-content: space-between;
  padding: 10px 15px;
  margin-top: -18px;
  background-color: #c5392e;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  color: black;
}

.chatbot-message{
  display: flex;
  flex-direction: column;    
  justify-content: space-between;
  padding: 10px 10px;
  margin-top: 0px;
}

.message{
  position:fixed; 
  bottom:10px; 
  right:30px;
}

.footer{
  position: fixed;
  bottom: 115px;
  height: 200px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.chatbot-header1 {
  position: fixed;
  display: flex;
  width: 350px;
  height: 288px;
  justify-content: space-between;
  background-color: #c5392e;
  align-items: left;
  padding: 1px 10px;
  font-weight: 500;
  font-size: 15px;
  z-index: -1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-top: 0px;

}

.chatbot-header2 {
  position: fixed;
  display: flex;
  width: 350px;
  height: 150px;
  justify-content: space-between;
  background-color: #c5392e;
  align-items: left;
  padding: 1px 10px;
  font-weight: 500;
  font-size: 15px;
  z-index: -1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}


.footer1{
  position: fixed;
  background-color: #f0f4f9;
  width: 350px;
  font-weight: 500;
  font-size: 10px;
  bottom: 115px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.footer2{
  position: fixed;
  background-color: #f0f4f9;
  width: 350px;
  font-weight: 500;
  font-size: 10px;
  bottom: 115px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.inputgetter{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-around;
}

.inputgetter1{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 10px;
  align-items: center;
  justify-content: space-around;
}

.chatbot-messages1{
  bottom: 39px;
  z-index: -1;
  background-color: #f0f4f9;
  overflow-y: scroll;
  position:relative;
  height: 512px;
  width:350px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.chatbot-chatarea{
  display: flex;
  flex-direction: column;
  width: 325px;
  margin-top: 53px;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: space-between;
}


.chatbot-messages1-img{
  bottom: 46px;
  position:absolute;
  background-color: #f0f4f9;
  height: 286px;
  width: 350px;
  z-index: -1;
}

.chatbot-chatarea-img{
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
 
.chat{
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: inherit;
}
.chat-left{
  display: flex;
  justify-content: flex-start;
}

.chat-right{
  display: flex;
  justify-content: flex-end;
}

.chat-message-sent {
  /* float: right;   */
  direction: ltr;
  background-color: white;
  padding: 8px 8px;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 100ms;
  color: black;
  border-radius: 10px 0px 10px 10px;
  font-size: 15px;
  text-align: left;
  width: fit-content;
  max-width: 250px;
  word-wrap: break-word;
  margin-right: -18px;
}

.chat-message-received {
  background-color: #fce1ca;
  direction:ltr;
  padding: 8px 8px;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 100ms;
  color: black;
  border-radius: 0px 10px 10px 10px;
  font-size: 15px;
  width: fit-content;
  /* max-width: 250px; */
  max-width: 265px;
  word-wrap: break-word;
  margin-left: -18px;
}

.linespace{
  height: 20px;
  width : inherit;
  background-color: inherit;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.center-box1{
  width: 350px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.box1 {
  display: flex;
  flex-direction: row;
  word-wrap: break-word;
  /* width: 230px; */
  width: 280px;
  height: fit-content;
  border-radius: 10px;
  background-color: #f8993d;
  padding: 24px 24px;
}

.box2 {
  right: -25px;
  position: relative;
  display: flex;
  flex-direction: row;
  word-wrap: break-word;
  width: 300px;
  height: 60px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: #fdd1ac;
}

.popup-box{
  position: fixed;
  background-color: white;
  width: 351px;
  height: 385px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: grey;
  font-weight: 500;
  font-size: 10px;
  bottom: 115px;
}

.image-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  /* width: 80px;
  height: 85px; */
  width: 105px;
  height: 100px;
  background-color: #eaedf0;
  padding: 10px 10px;
  word-wrap: break-word;
  text-align: center;
  color: black;
  cursor: pointer;
}
.inputfield-gap{
  margin-top: 16px;
}

.chatbot-icon2{
  width: 180px;
  height: 140px;
}
/* .chatbot-v1{
  color: white;
  font-family: '"Roboto Condensed" sans-serif' ;
  font-weight: 'bold';
  font-size: '2rem';
} */

.chatbot-welcome{
  font-size:large;
  margin: 0px 0px;
}

.triangle-left {
	width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 15px solid #fce1ca;
}
.triangle-right {
	width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-top: 15px solid #FFF;
}

.menu-box{
  display: flex;
  width: 250px;
  height: fit-content;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 25px;

}

/* .chatbot-v1{
  font-size: large;
  margin: 0px 0px;
} */

.chat-respones-link{ 
  color: #1976d2;
  margin-left: 16px;
  margin-top: -45px;
  margin-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px; 
  font-size: 13px;
  width: fit-content;
  max-width: 190px;
  word-wrap: break-word;
  text-decoration: none;
}
.blacktransparent{
  position: fixed;
  bottom:441px; 
  right: 30px;
  width: 351px;
  height: 275px;
  background-color: rgba(0, 0, 0, 0.5); /* black with 50% opacity */
  z-index: -1; /* to ensure it's on top of other elements */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.form {
  height: 75px;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
    align-items: center;
}
.list{
  padding-left: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
}

@media screen and (max-width: 768px) {
  .chat-icon1{
    width: 90px;
    height: 75px;
  }
  .chatbot {
    display: flex;
    flex-direction: column;
    height: 540px;
    width: 350px;
    /* border: 1px solid rgba(197, 194, 194, 0.603); */
    border-radius: 7px;
    box-shadow:0px 0px 5px 5px rgb(87 87 87 / 50%);
    background-color: #c5392e;
    position:fixed; 
    bottom:100px; 
    right:20px;
  }
  
  .chatbot1 {
    display: flex;
    flex-direction: column;
    height: 540px;
    width: 350px;
    /* border: 1px solid rgba(197, 194, 194, 0.603); */
    border-radius: 7px;
    box-shadow:0px 0px 5px 5px rgb(87 87 87 / 50%);
    position:fixed; 
    bottom: 100px; 
    right:20px;
  }
  .chatbot-messages {
    margin-top: 20px;
    margin-left: 24px;
    margin-right: 24px;
}
  .chatbot-header {
    display: block;
    height: 120px;
    justify-content: space-between;
    padding: 5px 10px;
    background-color: #c5392e;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    color: black;
    margin-top: -2px;
  }

  .inputfield-gap{
    margin-top: 16px;
  }
  .chatbot-message {
    display: flex;
    flex-direction: column;    
    justify-content: space-between;
    padding: 5px 20px;
    margin-top: 0px;
}
  .chat-logo-box{
    width: 240px;
    height: 40px;
  }
  .chat-logo{
    width: inherit;
    height: inherit;
  }
  .footer{
    position: fixed;
    bottom: 100px;
    width: 350px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .chatbot-icon2{
    width: 140px;
    height: 105px;
  }
  .chatbot-header1 {
    position: fixed;
    display: flex;
    width: 350px;
    height: 250px;
    justify-content: space-between;
    background-color: #c5392e;
    padding: 1px 10px;
    font-weight: 500;
    font-size: 15px;
    z-index: -1;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: 0px;
  }
  .chatbot-messages1-img {
    bottom: 46px;
    position: absolute;
    background-color: #f0f4f9;
    height: 255px;
    width: 350px;
    z-index: -1;
  }
  .center-box1{
    width: 350px;
    height: 370px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .box1 {
    display: flex;
    flex-direction: row;
    word-wrap: break-word;
    /* width: 230px; */
    width: 285px;
    height: fit-content;
    border-radius: 10px;
    background-color: #f8993d;
    padding: 24px 24px;
  }
  .footer1{
    position: fixed;
    background-color: #f0f4f9;
    width: 350px;
    font-weight: 500;
    font-size: 10px;
    bottom: 100px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .footer2{
    position: fixed;
    background-color: #f0f4f9;
    width: 350px;
    font-weight: 500;
    font-size: 10px;
    bottom: 100px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .popup-box{
    /* position: fixed; */
    background-color: white;
    width: 350px;
    height: 375px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    color: grey;
    font-weight: 500;
    font-size: 10px;
    bottom: 100px;
  }
  .chatbot-welcome{
    font-size: medium;
    margin: 0px 0px;

  }
  .chatbot-v1{
    font-size: large;
    margin: 0px 0px;
  }
  .blacktransparent {
    position: fixed;
    bottom: 460px;
    /* right: 21px; */
    right: 20px;
    width: 350px;
    height: 180px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
} 

@media screen and (max-width: 376px) {
  .chat-icon1{
    width: 90px;
    height: 70px;
  }
  .chatbot {
    display: flex;
    flex-direction: column;
    height: 540px;
    width: 330px;
    /* border: 1px solid rgba(197, 194, 194, 0.603); */
    border-radius: 7px;
    box-shadow:0px 0px 5px 5px rgb(87 87 87 / 50%);
    background-color: #c5392e;
    position:fixed; 
    bottom:100px; 
    right:10px;
  }
  
  .chatbot1 {
    display: flex;
    flex-direction: column;
    height: 540px;
    width: 330px;
    /* border: 1px solid rgba(197, 194, 194, 0.603); */
    border-radius: 7px;
    box-shadow:0px 0px 5px 5px rgb(87 87 87 / 50%);
    position:fixed; 
    bottom: 100px; 
    right:10px;
  }
  .chatbot-messages {
    margin-top: 20px;
    margin-left: 24px;
    margin-right: 24px;
}
  .chatbot-header {
    display: block;
    height: 120px;
    justify-content: space-between;
    padding: 5px 10px;
    background-color: #c5392e;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    color: black;
    margin-top: 0px;
  }
  .inputfield-gap{
    margin-top: 16px;
  }
  .chatbot-message {
    display: flex;
    flex-direction: column;    
    justify-content: space-between;
    padding: 0px 20px;;
    margin-top: 5px;
}
  .chat-logo-box{
    width: 230px;
    height: 40px;
  }
  .chat-logo{
    width: inherit;
    height: inherit;
  }
  .footer{
    position: fixed;
    bottom: 100px;
    width: 330px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .chatbot-icon2{
    width: 140px;
    height: 101px;
  }
  .chatbot-header1 {
    position: fixed;
    display: flex;
    width: 330px;
    height: 250px;
    justify-content: space-between;
    background-color: #c5392e;
    padding: 1px 10px;
    font-weight: 500;
    font-size: 15px;
    z-index: -1;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-top: 0px;
}
.chatbot-messages1-img {
  bottom: 46px;
  position: absolute;
  background-color: #f0f4f9;
  height: 244px;
  width: 330px;
  z-index: -1;
}
.center-box1{
  width: 330px;
  height: 360px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.box1 {
  display: flex;
  flex-direction: row;
  word-wrap: break-word;
  /* width: 250px; */
  width: 270px;
  height: fit-content;
  border-radius: 10px;
  background-color: #f8993d;
  padding: 16px 16px;
}
.box2 {
  right: -14px;
  position: relative;
  display: flex;
  flex-direction: row;
  word-wrap: break-word;
  width: 300px;
  height: 60px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: #fdd1ac;
}
.footer1{
  position: fixed;
  background-color: #f0f4f9;
  width: 330px;
  font-weight: 500;
  font-size: 10px;
  bottom: 100px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.footer2{
  position: fixed;
  background-color: #f0f4f9;
  width: 330px;
  font-weight: 500;
  font-size: 10px;
  bottom: 100px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.chatbot-header2 {
  position: fixed;
  display: flex;
  width: 330px;
  height: 150px;
  justify-content: space-between;
  background-color: #c5392e;
  align-items: left;
  padding: 1px 10px;
  font-weight: 500;
  font-size: 15px;
  z-index: -1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.popup-box{
  /* position: fixed; */
  background-color: white;
  width: 330px;
  height: 370px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: grey;
  font-weight: 500;
  font-size: 10px;
  bottom: 100px;
}
.chatbot-welcome{
  font-size: small;
  margin: 0px 0px;
}
.chatbot-v1{
  font-size: x-large;
  margin: 8px 0px;
}
.inputgetter{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-around;
  margin-left: 2px;
}
.inputgetter1 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 8px;
  align-items: center;
  justify-content: space-around;
  margin-left: 1px;
}
.chatbot-messages1 {
  bottom: 39px;
  z-index: -1;
  background-color: #f0f4f9;
  overflow-y: scroll;
  position: relative;
  height: 512px;
  width: 330px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.chatbot-chatarea {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-top: 53px;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: space-between;
}
.blacktransparent {
  position: fixed;
  /* bottom: 460px; */
  bottom: 455px;
  right: 10px;
  width: 330px;
  /* height: 180px; */
  height: 185px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
} 

.chat-message-received-1{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #fce1ca;
  direction:ltr;
  padding: 8px 8px;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 100ms;
  border-radius: 0px 10px 10px 10px;
  width: fit-content;
  max-width: 250px;
  margin-left: -18px;
}

.loadingsymbol{
  display:flex;
  justify-content: center;
  align-items: center;
  width:80px;
   height:30px;

}

.dot-flashing {
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #666666;
  color: #666666;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #666666;
  color: #666666;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #666666;
  color: #666666;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #666666;
  }
  50%, 100% {
    background-color: #999999;
  }
}
